<template>
  <v-card>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="swings"
      :search="search"
    ></v-data-table>
    <div>
      <v-card-title> Arm Rotaion </v-card-title>
      <v-combobox
        v-model="selectedSwingsArmRotation"
        :items="swingNames"
        label="Select swing"
        multiple
        chips
        clearable
        dense
      ></v-combobox>
      <div id="armRotation"></div>
    </div>
    <div>
      <v-card-title> Velocity </v-card-title>
      <v-combobox
        v-model="selectedSwingsVelocity"
        :items="swingNames"
        label="Select swing"
        multiple
        chips
        clearable
        dense
      ></v-combobox>
      <div id="velocity"></div>
    </div>
  </v-card>
</template>

<script>
var Swing = require("../helpers/swing.js");
var dataExtractor = new Swing();
var Plotly = require("plotly.js-dist");
export default {
  props: ["allSwings"],
  data() {
    return {
      search: "",
      headers: [
        {
          text: "Swing UID",
          align: "start",
          filterable: false,
          value: "name",
        },
        {
          text: "Timestamp",
          value: "timestamp",
        },
        { text: "Club", value: "club" },
        { text: "Max Acc Target (m/s^2)", value: "maxAy" },
        { text: "Min Acc Target after max (m/s^2)", value: "minAy" },
        { text: "Max velocity Target (mph)", value: "maxHsY" },
        { text: "Downswing Time (s)", value: "DSTime" },
        { text: "start to impact (s)", value: "startToImpact" },
        { text: "Max Velocity (mph)", value: "maxVel" },
        {
          text: "Max Velocity Distance to Impact (m)",
          value: "maxHsDistToImpact",
        },
        { text: "length of Backswing (m)", value: "LoB" },
        { text: "Range of Motion (m^2 * 100)", value: "RoM" },
        { text: "gyrX_closure_rate (deg/cm)", value: "gyrXClosure" },
        { text: "gyrX_tot_closure_rate (deg/~0,5m)", value: "gyrXTotClosure" },
        { text: "gyrY_closure_rate (deg/cm)", value: "gyrYClosure" },
        { text: "gyrY_tot_closure_rate (deg/~0,5m)", value: "gyrYTotClosure" },
        { text: "gyrZ_closure_rate (deg/cm)", value: "gyrZClosure" },
        { text: "gyrZ_tot_closure_rate (deg/~0,5m)", value: "gyrZTotClosure" },
        { text: "par_accX (m/s2)", value: "parAccX" },
        { text: "par_accZ (m/s2)", value: "parAccZ" },
        { text: "Power Score [0-100]", value: "powerScore" },
        {
          text: "Predicted Club Head Speed with driver (mph)",
          value: "CHS",
        },
      ],
      swings: [],
      swingNames: [],
      selectedSwingsArmRotation: [],
      selectedSwingsVelocity: [],
    };
  },
  created: function () {
    this.selectedDocuments = this.allSwings[0].data;
    console.log(this.allSwings[0].data[0]);
    for (let i = 0; i < this.selectedDocuments.length; i++) {
      let swing = this.selectedDocuments[i];
      this.swingNames.push(
        swing.metadata.timestamp + ", id: " + swing.metadata.documentId
      );
    }
    for (let i = 0; i < this.selectedDocuments.length; i++) {
      try {
        try {
          var clubName = this.selectedDocuments[i].metadata.club.name;
        } catch (error) {
          clubName = "n/a";
        }
        try {
          var docId = this.selectedDocuments[i].metadata.documentId;
        } catch (error) {
          docId = "n/a";
        }
        try {
          var maxAy = this.selectedDocuments[i].power_score.input.max_ay;
        } catch (error) {
          maxAy = "n/a";
        }
        try {
          var rom = this.selectedDocuments[i].power_score.input.RoM;
        } catch (error) {
          rom = "n/a";
        }
        try {
          var maxHsY = this.selectedDocuments[i].power_score.input.max_vy;
        } catch (error) {
          maxHsY = "n/a";
        }
        try {
          var minAy =
            this.selectedDocuments[i].power_score.input.min_ay_after_max_ay;
        } catch (error) {
          minAy = "n/a";
        }
        try {
          var DSTime = this.selectedDocuments[i].power_score.input.tempo_ds;
        } catch (error) {
          DSTime = "n/a";
        }
        try {
          var startToImpact =
            this.selectedDocuments[i].power_score.input.start_to_impact;
        } catch (error) {
          startToImpact = "n/a";
        }
        try {
          var maxVel = this.selectedDocuments[i].power_score.input.max_hs;
        } catch (error) {
          maxVel = "n/a";
        }
        try {
          var maxHsDistToImpact =
            this.selectedDocuments[i].power_score.input.max_hs_dist_to_impact;
        } catch (error) {
          maxHsDistToImpact = "n/a";
        }
        try {
          var LoB = this.selectedDocuments[i].power_score.input.lob;
        } catch (error) {
          LoB = "n/a";
        }
        try {
          var gyrXClosure =
            this.selectedDocuments[i].power_score.input.gyrX_closure_rate;
        } catch (error) {
          gyrXClosure = "n/a";
        }
        try {
          var gyrXTotClosure =
            this.selectedDocuments[i].power_score.input.gyrX_tot_closure_rate;
        } catch (error) {
          gyrXTotClosure = "n/a";
        }
        try {
          var gyrYClosure =
            this.selectedDocuments[i].power_score.input.gyrX_closure_rate;
        } catch (error) {
          gyrYClosure = "n/a";
        }
        try {
          var gyrYTotClosure =
            this.selectedDocuments[i].power_score.input.gyrY_tot_closure_rate;
        } catch (error) {
          gyrYTotClosure = "n/a";
        }
        try {
          var gyrZClosure =
            this.selectedDocuments[i].power_score.input.gyrZ_closure_rate;
        } catch (error) {
          gyrZClosure = "n/a";
        }
        try {
          var gyrZTotClosure =
            this.selectedDocuments[i].power_score.input.gyrZ_tot_closure_rate;
        } catch (error) {
          gyrZTotClosure = "n/a";
        }
        try {
          var parAccX = this.selectedDocuments[i].power_score.input.par_accX;
        } catch (error) {
          parAccX = "n/a";
        }
        try {
          var parAccZ = this.selectedDocuments[i].power_score.input.par_accZ;
        } catch (error) {
          parAccZ = "n/a";
        }
        try {
          var powerScore = this.selectedDocuments[i].power_score.Power_Score;
        } catch (error) {
          powerScore = "n/a";
        }
        try {
          var CHS = powerScore * 150;
        } catch (error) {
          powerScore = "n/a";
        }
        let data = {
          name: docId,
          club: clubName,
          maxAy: maxAy.toFixed(4),
          RoM: rom.toFixed(4),
          maxHsY: maxHsY.toFixed(4),
          minAy: minAy.toFixed(4),
          DSTime: DSTime.toFixed(4),
          startToImpact: startToImpact.toFixed(4),
          maxVel: maxVel.toFixed(4),
          maxHsDistToImpact: maxHsDistToImpact.toFixed(4),
          LoB: LoB.toFixed(4),
          gyrXClosure: gyrXClosure.toFixed(4),
          gyrXTotClosure: gyrXTotClosure.toFixed(4),
          gyrYClosure: gyrYClosure.toFixed(4),
          gyrYTotClosure: gyrYTotClosure.toFixed(4),
          gyrZClosure: gyrZClosure.toFixed(4),
          gyrZTotClosure: gyrZTotClosure.toFixed(4),
          parAccX: parAccX.toFixed(4),
          parAccZ: parAccZ.toFixed(4),
          timestamp: this.selectedDocuments[i].metadata.timestamp,
          powerScore: (powerScore * 100).toFixed(2),
          CHS: CHS.toFixed(2),
        };
        this.swings.push(data);
      } catch {
        continue;
      }
    }
  },
  methods: {
    getSwings(chosenSwings) {
      var selectedSwings = [];
      chosenSwings.forEach((document) => {
        let docId = document.split("id: ")[1];
        for (let i = 0; i < this.selectedDocuments.length; i++) {
          if (this.selectedDocuments[i].metadata.documentId === docId) {
            selectedSwings.push(this.selectedDocuments[i]);
          }
        }
      });
      return selectedSwings;
    },
  },
  watch: {
    selectedSwingsArmRotation: function () {
      let doc = this.getSwings(this.selectedSwingsArmRotation)[0];
      var armRotatation = dataExtractor.getArmRotationXYZ(doc);
      var intGyrX = armRotatation.x;
      var intGyrY = armRotatation.y;
      var intGyrZ = armRotatation.z;
      var time = dataExtractor.linspace(
        0,
        intGyrX.length / 100,
        intGyrX.length
      );
      try {
        Plotly.purge(document.getElementById("armRotation"));
      } catch (error) {
        console.log(error, "Nothing to purge");
      }

      let dataGyrX = {
        x: time,
        y: intGyrX,
        mode: "lines",
        name: "x",
        line: {
          color: "blue",
          width: 5.5,
        },
      };
      let dataGyrY = {
        x: time,
        y: intGyrY,
        mode: "lines",
        name: "y",
        line: {
          color: "green",
          width: 5.5,
        },
      };
      let dataGyrZ = {
        x: time,
        y: intGyrZ,
        mode: "lines",
        name: "Z",
        line: {
          color: "red",
          width: 5.5,
        },
      };
      let tempo = dataExtractor.getTempo(doc);
      let turn = tempo.backswing_time;
      let impact = tempo.backswing_time + tempo.downswing_time;
      let max = Math.max(...intGyrX);
      let min = Math.min(...intGyrX);
      let traces = [dataGyrX, dataGyrY, dataGyrZ];
      let verticalLineTurn = {
        type: "line",
        x0: turn,
        y0: min,
        x1: turn,
        y1: max,
        line: {
          color: (128, 128, 128),
          width: 1,
          dash: "dashdot",
        },
      };
      let verticalLineImpact = {
        type: "line",
        x0: impact,
        y0: min,
        x1: impact,
        y1: max,
        line: {
          color: (128, 128, 128),
          width: 1,
          dash: "dashdot",
        },
      };
      let shapes = [verticalLineTurn, verticalLineImpact];
      var layout = {
        title: "",
        xaxis: {
          title: " s (dt = 0.01)",
        },
        yaxis: {
          title: "Arm Rotation (deg)",
        },
        shapes: shapes,
        legend: {
          yanchor: "top",
          xanchor: "center",
        },
        font: {
          size: 7,
        },
      };
      Plotly.newPlot(document.getElementById("armRotation"), traces, layout);
    },
    selectedSwingsVelocity: function () {
      let doc = this.getSwings(this.selectedSwingsVelocity)[0];
      let data = dataExtractor.getVelocityInAllDirections(doc);
      let vx = data.vX;
      let vy = data.vY;
      let vz = data.vZ;
      let velM = data.vM;
      var time = dataExtractor.linspace(0, vx.length / 100, vx.length);

      try {
        Plotly.purge(document.getElementById("velocity"));
      } catch (error) {
        console.log(error, "Nothing to purge");
      }

      let dataVelX = {
        x: time,
        y: vx,
        mode: "lines",
        name: "x",
        line: {
          color: "blue",
          width: 5.5,
        },
      };
      let dataVelY = {
        x: time,
        y: vy,
        mode: "lines",
        name: "y",
        line: {
          color: "green",
          width: 5.5,
        },
      };
      let dataVelZ = {
        x: time,
        y: vz,
        mode: "lines",
        name: "Z",
        line: {
          color: "red",
          width: 5.5,
        },
      };
      let dataVM = {
        x: time,
        y: velM,
        mode: "lines",
        name: "vel",
        line: {
          color: "black",
          width: 5.5,
        },
      };
      let tempo = dataExtractor.getTempo(doc);
      let turn = tempo.backswing_time;
      let impact = tempo.backswing_time + tempo.downswing_time;
      let max = Math.max(...velM);
      let min = Math.min(...velM);
      let traces = [dataVelX, dataVelY, dataVelZ, dataVM];
      let verticalLineTurn = {
        type: "line",
        x0: turn,
        y0: min,
        x1: turn,
        y1: max,
        line: {
          color: "gray",
          width: 1,
          dash: "dashdot",
        },
      };
      let verticalLineImpact = {
        type: "line",
        x0: impact,
        y0: min,
        x1: impact,
        y1: max,
        line: {
          color: "gray",
          width: 1,
          dash: "dashdot",
        },
      };
      let shapes = [verticalLineTurn, verticalLineImpact];
      var layout = {
        title: "",
        xaxis: {
          title: " s (dt = 0.01)",
        },
        yaxis: {
          title: "Velocity (m/s)",
        },
        shapes: shapes,
        legend: {
          yanchor: "top",
          xanchor: "center",
        },
        font: {
          size: 7,
        },
      };
      Plotly.newPlot(document.getElementById("velocity"), traces, layout);
    },
  },
};
</script>
