<template>
  <v-card>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="swings"
      :search="search"
    ></v-data-table>
  </v-card>
</template>

<script>
/* eslint-disable */
export default {
  props: ["allSwings"],
  data() {
    return {
      search: "",
      headers: [
        {
          text: "Swing UID",
          align: "start",
          filterable: false,
          value: "name",
        },
        { text: "Battery Voltage", value: "batt_voltage" },
        {
          text: "Charge complete to pain (s)",
          value: "charge_complete_to_pain_s",
        },
        { text: "Charge System time", value: "charge_systime" },
        { text: "Charge Voltage", value: "charge_voltage" },
        { text: "Complete System time", value: "complete_systime" },
        { text: "Pulse length (us)", value: "pulse_length_us" },
        { text: "Status", value: "status_" },
        { text: "Temperature (celcius)", value: "temp" },
        { text: "v1", value: "v1" },
        { text: "v2", value: "v2" },
        { text: "z load", value: "z_load" },
      ],
      swings: [],
    };
  },
  created: function () {
    this.selectedDocuments = this.allSwings[0].data;
    console.log('typeof', typeof this.selectedDocuments[0])
    for (let i = 0; i < this.selectedDocuments.length; i++) {
      let data = {
        name: this.selectedDocuments[i].metadata.documentId,
        batt_voltage:
          this.selectedDocuments[i].parsedBlob.sting_info.batt_voltage,
        charge_complete_to_pain_s:
          this.selectedDocuments[i].parsedBlob.sting_info
            .charge_complete_to_pain_s,
        charge_systime:
          this.selectedDocuments[i].parsedBlob.sting_info.charge_systime,
        charge_voltage:
          this.selectedDocuments[i].parsedBlob.sting_info.charge_voltage,
        complete_systime:
          this.selectedDocuments[i].parsedBlob.sting_info.complete_systime,
        pulse_length_us:
          this.selectedDocuments[i].parsedBlob.sting_info.pulse_length_us,
        status_: this.selectedDocuments[i].parsedBlob.sting_info.status,
        temp: this.selectedDocuments[i].parsedBlob.sting_info.temp,
        v1: this.selectedDocuments[i].parsedBlob.sting_info.v1,
        v2: this.selectedDocuments[i].parsedBlob.sting_info.v2,
        z_load: this.selectedDocuments[i].parsedBlob.sting_info.z_load,
      };
      this.swings.push(data);
    }
  },
  methods: {},
};
</script>
