/* eslint-disable */
module.exports = class Swing {
  constructor() {
    this.deltaT = 0.01;
    this.documets = [];
    this.RAD2DEG = 180 / 3.14;
  }

  dotProduct(v0, v1) {
    var val = 0;
    for (let i = 0; i < v0.length; i++) {
      val += v0[i] * v1[i];
    }
    return val;
  }

  getMeanAndStd(v) {
    const sum = v.reduce((a, b) => a + b, 0);
    const mean = sum / v.length || 0;
    var std = Math.sqrt(
      v.map((x) => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / v.length
    );
    return [mean, std];
  }

  getBsPlaneAngle(document) {
    let n = document.parsedBlob.planefit_info.backswing.n;
    let ground_vector = [0, 0, 1];
    let theta = this.dotProduct(n, ground_vector);
    return Math.acos(theta) * this.RAD2DEG;
  }

  getMaxSpeedAndTime(document) {
    let vel = this.getSpeed(document);
    let max = Math.max(...vel);
    let time = vel.indexOf(max) * 0.01;
    return [max, time];
  }

  getPos(document) {
    let x = [];
    let y = [];
    let z = [];
    document.parsedBlob.position_data.forEach((pos) => {
      x.push(pos.PosX);
      y.push(pos.PosY);
      z.push(pos.PosZ);
    });

    let positions = {
      posX: x,
      posY: y,
      posZ: z,
    };

    return positions;
  }

  getWidthAtTime(document) {
    let x = [];
    let y = [];
    let z = [];
    document.parsedBlob.position_data.forEach((pos) => {
      x.push(pos.PosX);
      y.push(pos.PosY);
      z.push(pos.PosZ);
    });
    let turnIndex = Math.floor(
      document.parsedBlob.tempo_analysis.backswing_time * 100
    );
    let yStartToTurn = y.slice(0, turnIndex);
    let widths = [];

    for (let i = 0; i < yStartToTurn.length; i++) {
      let width = Math.sqrt(
        (y[0] - yStartToTurn[i]) * (y[0] - yStartToTurn[i])
      );
      widths.push(width);
    }

    let widthMax = Math.max(...widths);
    let time = widths.indexOf(widthMax) * 0.01;

    return [widthMax, time];
  }

  distance3d(p1, p2) {
    return Math.sqrt(
      (p1[0] - p2[0]) * (p1[0] - p2[0]) +
        (p1[1] - p2[1]) * (p1[1] - p2[1]) +
        (p1[2] - p2[2]) * (p1[2] - p2[2])
    );
  }

  getSpeed(document) {
    let x = [];
    let y = [];
    let z = [];
    document.parsedBlob.position_data.forEach((pos) => {
      x.push(pos.PosX);
      y.push(pos.PosY);
      z.push(pos.PosZ);
    });
    var vel = [];
    for (let i = 0; i < x.length - 1; i++) {
      let p1 = [x[i], y[i], z[i]];
      let p2 = [x[i + 1], y[i + 1], z[i + 1]];
      let dist = this.distance3d(p1, p2);
      let velM = dist / this.deltaT;
      vel.push(velM);
    }

    return vel;
  }

  linspace(startValue, stopValue, cardinality) {
    var arr = [];
    var step = (stopValue - startValue) / (cardinality - 1);
    for (var i = 0; i < cardinality; i++) {
      arr.push(startValue + step * i);
    }
    return arr;
  }

  getTempo(document) {
    return document.parsedBlob.tempo_analysis;
  }

  getArmRotation(document) {
    var gyrX = document.parsedBlob.gyr_x;
    var intGyrX = 0;
    var intGyrXArray = [];
    for (let i = 0; i < gyrX.length; i++) {
      intGyrX += gyrX[i] * this.deltaT;
      intGyrXArray.push(intGyrX);
    }
    return intGyrXArray;
  }

getArmRotationXYZ(document) {
    var gyrs = document.parsedBlob.list_of_dbg_vectors.gyr_xsens_bf;
    var intGyrX = 0;
    var intGyrY = 0;
    var intGyrZ = 0;
    var intGyrXArray = [];
    var intGyrYArray = [];
    var intGyrZArray = [];
    for (let i = 0; i < gyrs.length; i += 3) {
      if (isNaN(gyrs[i])) {
        break;
      }
      intGyrX += gyrs[i] * this.deltaT;
      intGyrXArray.push(intGyrX);
    }
    for (let i = 1; i < gyrs.length; i += 3) {
      if (isNaN(gyrs[i])) {
        break;
      }
      intGyrY += gyrs[i] * this.deltaT;
      intGyrYArray.push(intGyrY);
    }

    for (let i = 2; i < gyrs.length; i += 3) {
      if (isNaN(gyrs[i])) {
        break;
      }
      intGyrZ += gyrs[i] * this.deltaT;
      intGyrZArray.push(intGyrZ);
    }

    var data = {
      x : intGyrXArray,
      y : intGyrYArray,
      z : intGyrZArray
    }
    return data;
  }

  getVelocityInAllDirections(document) {
    var accs = document.parsedBlob.list_of_dbg_vectors.acc_xsens;
    var intAccX = 0;
    var intAccXArray = []
    var intAccY = 0;
    var intAccYArray = []
    var intAccZ = 0;
    var intAccZArray = []

    for (let i = 0; accs.length; i += 3) {
      if (isNaN(accs[i])) {
        break;
      }
      intAccX += accs[i] * this.deltaT;
      intAccXArray.push(intAccX);
    }
    for (let i = 1; accs.length; i += 3) {
      if (isNaN(accs[i])) {
        break;
      }
      intAccY += accs[i] * this.deltaT;
      intAccYArray.push(intAccY);
    }
    for (let i = 2; accs.length; i += 3) {
      if (isNaN(accs[i])) {
        break;
      }
      intAccZ += (accs[i] - 9.82) * this.deltaT;
      intAccZArray.push(intAccZ);
    }
    var velocityMagnitude = []
    var intVel = 0;
    for (let i = 0; i < intAccXArray.length; i++) {
      intVel = Math.sqrt(intAccXArray[i] * intAccXArray[i] + intAccYArray[i] * intAccYArray[i] + intAccZArray[i] * intAccZArray[i]);
      velocityMagnitude.push(intVel)
    }
    var data = {
      vX : intAccXArray,
      vY : intAccYArray,
      vZ : intAccZArray,
      vM : velocityMagnitude,
    }

    return data
  }

  getDataPoint(document, variable) {
    if (variable === "LoB") {
      try {
        return document.parsedBlob.lob_analysis.lob_dist * 100;
      } catch (error) {
        return;
      }
    }
    if (variable === "Transition") {
      try {
        return document.parsedBlob.ott_analysis.result_value * 100;
      } catch (error) {
        return;
      }
    }
    if (variable === "Max hand speed") {
      return Math.max(...this.getSpeed(document));
    }
    if (variable === "Max hand speed time") {
      let speed = this.getSpeed(document);
      let max = Math.max(...speed);
      return speed.indexOf(max) * 0.01;
    }
    if (variable === "Time turn") {
      return document.parsedBlob.timestamp_data.time_turn;
    }
    if (variable === "Time impact") {
      return document.parsedBlob.timestamp_data.time_downswing;
    }
    if (variable === "Max gyr x") {
      return Math.max(...this.getArmRotation(document));
    }
    if (variable === "Min gyr x") {
      return Math.min(...this.getArmRotation(document));
    }
    if (variable === "Backswing plane") {
      return this.getBsPlaneAngle(document);
    }
  }

  getSessionRejects(terminal) {
    terminal = terminal.toString();
    var lines = terminal.split("\n");
    var rejects = [];
    lines.forEach((line) => {
      if (line.toLowerCase().includes("rejected")) {
        rejects.push(line);
      }
    });
    return rejects;
  }
};
