<template>
  <v-card>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="swings"
      :search="search"
    ></v-data-table>
  </v-card>
</template>

<script>
export default {
  props: ["allSwings"],
  data() {
    return {
      search: "",
      headers: [
        {
          text: "Swing UID",
          align: "start",
          filterable: false,
          value: "name",
        },
        {
          text: "Timestamp",
          value: "timestamp",
        },
        { text: "Club", value: "club" },
        { text: "Sofware version", value: "softwareVersion" },
        { text: "Serial number", value: "serialNumber" },
        { text: "Active Feature", value: "activeFeature" },
        { text: "Stimuli Activated", value: "stimuliOn" },
      ],
      swings: [],
    };
  },
  created: function () {
    this.selectedDocuments = this.allSwings[0].data;

    for (let i = 0; i < this.selectedDocuments.length; i++) {
      try {
        try {
          var clubName = this.selectedDocuments[i].metadata.club.name;
        } catch (error) {
          clubName = "n/a";
        }
        try {
          var docId = this.selectedDocuments[i].metadata.documentId;
        } catch (error) {
          docId = "n/a";
        }
        try {
          var time = this.selectedDocuments[i].metadata.timestamp;
        } catch (error) {
          time = "n/a";
        }
        try {
          var serial =
            this.selectedDocuments[i].general.genericAppSettings.hardwareInfo
              .serialNumber;
        } catch (error) {
          docId = "n/a";
        }
        try {
          var stimuli =
            this.selectedDocuments[i].general.genericAppSettings.sting;
        } catch (error) {
          stimuli = "n/a";
        }
        try {
          var software =
            this.selectedDocuments[i].general.genericAppSettings.hardwareInfo
              .softwareVersion;
        } catch (error) {
          software = "n/a";
        }
        let data = {
          name: docId,
          club: clubName,
          timestamp: time,
          serialNumber: serial,
          activeFeature: this.getActiveFeature(
            this.selectedDocuments[i].general.featuresOnOffSettings
          ),
          stimuliOn: stimuli,
          softwareVersion: software,
        };
        this.swings.push(data);
      } catch {
        continue;
      }
    }
  },
  methods: {
    getActiveFeature(features) {
      let keys = Object.keys(features);
      var chosenFeature = "None";
      keys.forEach((key) => {
        if (features[key] === true) {
          chosenFeature = key;
        }
      });
      return chosenFeature;
    },
  },
};
</script>
