/* eslint-disable */
import axios from "axios";
// const apiPath = "http://localhost:7073";
const apiPath = "https://1835-06-function-dewizdash.azurewebsites.net";
export function getSwings(url, data) {
  return new Promise((resolve, reject) => {
    if (!url) {
      reject(new Error("No path"));
    }
    let payload = {
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": apiPath,
        "Origin": apiPath
      },
    };
    axios
      .post(apiPath + url, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.error(error);
      });
  });
}

export function getCustom(url) {
  return new Promise((resolve, reject) => {
    if (!url) {
      reject(new Error("No path"));
    }
    axios
      .get(apiPath + url)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.error(error);
      });
  });
}

export function apiCallerGet(url) {
  return new Promise((resolve, reject) => {
    if (!url) {
      reject(new Error("No path"));
    }
    axios
      .get(apiPath + url)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.error(error);
      });
  });
}

export function getTerminal(url) {
  return new Promise((resolve, reject) => {
    if (!url) {
      reject(new Error("No path"));
    }
    axios
      .get(apiPath + url)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        // eslint-disable-next-line
        console.log('Error')
        console.error(error);
      });
  });
}

export function getUserRejects(url) {
  return new Promise((resolve, reject) => {
    if (!url) {
      reject(new Error("No path"));
    }
    axios
      .get(apiPath + url)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("Error");
        console.error(error);
      });
  });
}

export function login(data) {
  return new Promise((resolve, reject) => {
    if (!data) {
      reject(new Error("No path"));
    }
    let url = `/login/${data["username"]}/${data["password"]}`;
    axios
      .get(apiPath + url)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.error(error);
      });
  });
}
