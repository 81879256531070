<template>
  <v-app>
    <v-container>
      <v-combobox
        v-model="selectedFeatures"
        :items="features"
        label="Select Features"
        multiple
        chips
        clearable
      ></v-combobox>
      <div v-if="showStingInfo">
        <sting-info :all-swings="swings" />
      </div>
      <div v-if="showSwingInfo">
        <discrete-data :all-swings="swings" />
      </div>
      <div v-if="showPlots">
        <plots :all-swings="swings"></plots>
      </div>
      <div v-if="showSessionTerminal">
        <session-terminal :all-swings="swings"></session-terminal>
      </div>
      <div v-if="showUserRejects">
        <user-rejects :all-swings="swings"></user-rejects>
      </div>
      <div v-if="showGeneral">
        <general-info :all-swings="swings"></general-info>
      </div>
      <div v-if="showBeta">
        <beta-user :all-swings="swings"></beta-user>
      </div>
    </v-container>
  </v-app>
</template>

<script>
/* eslint-disable */

import StingInfo from "@/components/StingInfo";
import GeneralInfo from "@/components/GeneralInfo";
import DiscreteData from "@/components/DiscreteData";
import Plots from "@/components/Plots";
import SessionTerminal from "@/components/SessionTerminal";
import UserRejects from "@/components/UserRejects";
import BetaUser from "@/components/BetaUser";

export default {
  components: {
    StingInfo,
    DiscreteData,
    Plots,
    SessionTerminal,
    UserRejects,
    GeneralInfo,
    BetaUser
  },
  props: ["swings"],
  data() {
    return {
      dateFrom: null,
      dateTo: null,
      uuid: null,
      nrSwings: null,
      nrSessions: null,
      email: null,
      documents: [],
      selectedDocuments: [],
      selectedSwings: [],
      showLine: false,
      selectedFeatures: [],
      showStingInfo: false,
      showSwingInfo: false,
      showPlots: false,
      showUserRejects: false,
      showSessionTerminal: false,
      showGeneral: false,
      showBeta: false,
      features:  [
        "Sting Info",
        "Swing Info (Discrete Data Points)",
        "Plots",
        "User Rejects",
        "Session Terminal",
        "General",
        "Power Score (Only for Beta users)"
      ],
    };
  },
  updated: function () {},
  created: function () {
    var docs = [];
    var swings_ = this.swings[0].data;

    swings_.forEach((element) => {
      let documentName =
        element.metadata.timestamp +
        ", Document id: " +
        element.metadata.documentId;
      docs.push(documentName);
    });
    this.documents = docs;
    var selectedSwings = [];
    this.selectedDocuments.forEach((document) => {
      let docId = document.split("Document id: ")[1];
      for (let i = 0; i < swings_.length; i++) {
        if (swings_[i].metadata.documentId === docId) {
          selectedSwings.push(swings_[i]);
        }
      }
    });
    this.selectedSwings = swings_;
  },
  methods: {},
  watch: {
    selectedFeatures: function () {
      // Some kind of state machine to keep track of what to show. I believe this can be done in another better way.
      // TODO: Refactor

      if (this.selectedFeatures.includes(this.features[0])) {
        this.showStingInfo = true;
      } else {
        this.showStingInfo = false;
      }
      if (this.selectedFeatures.includes(this.features[1])) {
        this.showSwingInfo = true;
      } else {
        this.showSwingInfo = false;
      }
      if (this.selectedFeatures.includes(this.features[2])) {
        this.showPlots = true;
      } else {
        this.showPlots = false;
      }
      if (this.selectedFeatures.includes(this.features[3])) {
        this.showUserRejects = true;
      } else {
        this.showUserRejects = false;
      }
      if (this.selectedFeatures.includes(this.features[4])) {
        this.showSessionTerminal = true;
      } else {
        this.showSessionTerminal = false;
      }
      if (this.selectedFeatures.includes(this.features[5])) {
        this.showGeneral = true;
      } else {
        this.showGeneral = false;
      }
      if (this.selectedFeatures.includes(this.features[6])) {
        this.showBeta = true;
      } else {
        this.showBeta = false;
      }
    },
  },
};
</script>

<style>
#loader {
  position: relative;
  margin: 0 auto;
  clear: left;
  height: auto;
  z-index: 0;
  text-align: center;
}
</style>
