<template>
  <v-card>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="swings"
      :search="search"
    ></v-data-table>
  </v-card>
</template>

<script>
/* eslint-disable */

var Swing = require("../helpers/swing.js");
var dataExtractor = new Swing();

export default {
  props: ["allSwings"],
  data() {
    return {
      search: "",
      headers: [
        {
          text: "Swing",
          align: "start",
          filteralbe: false,
          value: "name",
        },
        { text: "Tempo (bs, ds)", value: "tempo" },
        { text: "Length Of backswing (cm)", value: "lob" },
        { text: "Max hand Speed (m/s, time)", value: "MHS" },
        { text: "Transtion (cm)", value: "ott" },
        { text: "Transtion Arc (cm)", value: "ottArc" },
        { text: "Backswing Plane Angle", value: "bsPlane" },
        { text: "Max Width (width m, time)", value: "width" },
        { text: "Swing Direction", value: "swingDirection" },
        { text: "Arm Mount", value: "armMount" },
      ],
      swings: [],
    };
  },
  created: function () {
    this.populate();
  },
  methods: {
    populate() {
      this.selectedDocuments = this.allSwings[0].data
      var tempos_bs = [];
      var tempos_ds = [];
      var lobs = [];
      var MHSs = [];
      var MHSt = [];
      var otts = [];
      var bsplanes = [];
      var witdths = [];
      var witdthsT = [];
      for (let i = 0; i < this.selectedDocuments.length; i++) {
        let sw = this.selectedDocuments[i];
        if (sw === undefined) {
          continue;
        }
        let lob_ =  sw.parsedBlob.lob_analysis !== undefined ? (sw.parsedBlob.lob_analysis.lob_dist* 100).toFixed(5) + "cm" : 'n/a'
        let ott_ = sw.parsedBlob.ott_analysis !== undefined ? (sw.parsedBlob.ott_analysis.result_value * 100).toFixed(4) + "cm." : 'n/a'
        let ottArc_ = sw.parsedBlob.ott_analysis !== undefined ?  sw.parsedBlob.ott_analysis.bowl_dist.map(function (x) {return (x * 100).toFixed(2) + "cm " }) : 'n/a'
        let swingDir = sw.parsedBlob.swing_direction === 255 ? "Right" : "Left";
        let mount =
          sw.parsedBlob.arm_mount === 0 ? "Right wrist" : "Left wrist";
        let data = {
          name: sw.metadata.timestamp,
          tempo:
            sw.parsedBlob.tempo_analysis.backswing_time.toFixed(2) +
            "s (backswing), " +
            sw.parsedBlob.tempo_analysis.downswing_time.toFixed(2) +
            "s (downswing).",
          lob: lob_,
          MHS:
            dataExtractor.getMaxSpeedAndTime(sw)[0].toFixed(5) +
            "m/s at time: " +
            dataExtractor.getMaxSpeedAndTime(sw)[1].toFixed(2) +
            "s." || 'n/a',
          ott: ott_,
          ottArc: ottArc_,
          bsPlane: dataExtractor.getBsPlaneAngle(sw).toFixed(4) + "°.",
          width:
            (dataExtractor.getWidthAtTime(sw)[0] * 100).toFixed(4) +
            "cm at time: " +
            dataExtractor.getWidthAtTime(sw)[1].toFixed(2),
          swingDirection: swingDir,
          armMount: mount,
        };
        tempos_bs.push(sw.parsedBlob.tempo_analysis.backswing_time);
        tempos_ds.push(sw.parsedBlob.tempo_analysis.downswing_time);
        if (lob_ != 'n/a') {
          lobs.push(sw.parsedBlob.lob_analysis.lob_dist * 100);
        }
        let mhsArr = dataExtractor.getMaxSpeedAndTime(sw);
        MHSs.push(mhsArr[0]);
        MHSt.push(mhsArr[1]);
        if (ott_ != 'n/a') {
          otts.push(sw.parsedBlob.ott_analysis.result_dist * 100);
         }
        bsplanes.push(dataExtractor.getBsPlaneAngle(sw));
        witdths.push(dataExtractor.getWidthAtTime(sw)[0]);
        witdthsT.push(dataExtractor.getWidthAtTime(sw)[1]);
        this.swings.push(data);
      }

      let data = {
        name: "Mean and standard deviation",
        tempo:
          "Backswing: " +
          dataExtractor.getMeanAndStd(tempos_bs)[0].toFixed(2) +
          ", " +
          dataExtractor.getMeanAndStd(tempos_bs)[1].toFixed(2) +
          ". " +
          "Downswing: " +
          dataExtractor.getMeanAndStd(tempos_ds)[0].toFixed(2) +
          ", " +
          dataExtractor.getMeanAndStd(tempos_ds)[1].toFixed(2) +
          ".",
        lob:
          dataExtractor.getMeanAndStd(lobs)[0].toFixed(4) +
          ", " +
          dataExtractor.getMeanAndStd(lobs)[1].toFixed(4),
        MHS:
          "Velocity: " +
          dataExtractor.getMeanAndStd(MHSs)[0].toFixed(4) +
          ", " +
          dataExtractor.getMeanAndStd(MHSs)[1].toFixed(4) +
          "." +
          "Time: " +
          dataExtractor.getMeanAndStd(MHSt)[0].toFixed(4) +
          ", " +
          dataExtractor.getMeanAndStd(MHSt)[1].toFixed(4) +
          ".",
        ott:
          dataExtractor.getMeanAndStd(otts)[0].toFixed(4) +
          ", " +
          dataExtractor.getMeanAndStd(otts)[1].toFixed(4) +
          ".",
        ottArc: "-",
        bsPlane:
          dataExtractor.getMeanAndStd(bsplanes)[0].toFixed(4) +
          ", " +
          dataExtractor.getMeanAndStd(bsplanes)[1].toFixed(4),
        width:
          "Width: " +
          " " +
          dataExtractor.getMeanAndStd(witdths)[0].toFixed(4) +
          ", " +
          dataExtractor.getMeanAndStd(witdths)[1].toFixed(4) +
          ". " +
          "Time: " +
          " " +
          dataExtractor.getMeanAndStd(witdthsT)[0].toFixed(4) +
          ", " +
          dataExtractor.getMeanAndStd(witdthsT)[1].toFixed(4) +
          ".",
        swingDirection: "-",
        armMount: "-",
      };
      this.swings.push(data);
    },
  },
};
</script>
