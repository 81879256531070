<template>
  <v-container fluid>
    <v-row>
      <v-col order="first">
        <v-combobox
          v-model="selectedSessions"
          :items="sessionIds"
          label="Select Session"
          small-chips
          :menu-props="{ minWidth: '150', maxHeight: '400' }"
          clearable
        ></v-combobox>
      </v-col>
    </v-row>
    <v-row>
      <div class="center" id="swingInfo"></div>
      <div class="center" id="rejectBarChart"></div>
      <div class="center" id="terminalText"></div>
    </v-row>
  </v-container>
</template>

<script>
/* eslint-disable */
import * as apiCaller from "../helpers/apiCaller.js";
var Swing = require("../helpers/swing.js");
var dataExtractor = new Swing();
var Plotly = require("plotly.js-dist");

export default {
  props: ["allSwings"],
  data() {
    return {
      swings: [],
      sessionIds: [],
      selectedSessions: null,
      terminal: [],
      metaSession: null,
    };
  },
  created: function () {
    this.swings = this.allSwings[0].data;
    this.swings.forEach((swing) => {
      this.sessionIds.push(swing.metadata.sessionId);
    });
  },
  methods: {
    getPayload(sessionId) {
      var uid;
      this.swings.forEach((swing) => {
        if (swing.metadata.sessionId === sessionId) {
          uid = swing.uid;
        }
      });
      return uid;
    },
    fetchRejects() {
      let rejects = dataExtractor.getSessionRejects(this.terminal);

      const counts = {};
      for (const reject of rejects) {
        counts[reject] = counts[reject] ? counts[reject] + 1 : 1;
      }
      let keys = Object.keys(counts);
      let occurrences = [];
      for (let i = 0; i < keys.length; i++) {
        occurrences.push(counts[keys[i]]);
        keys[i] = keys[i].replace("Swing Rejected: ", "");
      }
      let data = [
        {
          x: keys,
          y: occurrences,
          type: "bar",
          marker: {
            color: "rgb(142,124,195)",
          },
        },
      ];

      var layout = {
        title: "Number of different Rejections in Session",
        autosize: true,
        font: {
          size: 8,
        },
      };
      Plotly.newPlot(document.getElementById("rejectBarChart"), data, layout);
    },
  },
  watch: {
    selectedSessions: function () {
      if (!this.selectedSessions) {
        return;
      }
      this.metaSession = null;
      let profile = this.getPayload(this.selectedSessions);
      let url = `/sessionSwings/${profile}/${this.selectedSessions}`;
      Promise.allSettled([apiCaller.apiCallerGet(url)]).then((response) => {
        this.metaSession = response[0].value.data;
      });
      this.terminal = [];
      url = `/sessionTerminal/${profile}/${this.selectedSessions}`;
      Promise.allSettled([apiCaller.getTerminal(url)]).then((response) => {
        this.terminal = response[0].value.data;
      });
    },
    terminal: function () {
      var mainContainer = document.getElementById("terminalText");
      mainContainer.innerHTML = "";
      var textField = document.createElement("pre");
      textField.className = "bash";
      textField.innerHTML = this.terminal;
      mainContainer.appendChild(textField);
      this.fetchRejects();

    },
    metaSession: function () {
      if (this.metaSession === null) {
        return;
      }
      var text = `\n
      Date: ${this.metaSession.metadata.timestamp} \n
      Firmware: ${this.metaSession.settings.genericAppSettings.hardwareInfo.softwareVersion}\n
      Number of swings in session: ${this.metaSession.number_of_swings}\n
      Number of rejections in session: ${this.metaSession.number_of_rejections}.`;

      let mainContainer = document.getElementById("swingInfo");
      mainContainer.innerHTML = "";
      let textField = document.createElement("pre");
      textField.className = "bash";
      textField.innerHTML = text;
      mainContainer.appendChild(textField);
    },
  },
};
</script>

<style>
pre.bash {
  background-color: black;
  color: white;
  font-size: medium;
  font-family: Consolas, Monaco, Lucida Console, Liberation Mono,
    DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace;
  width: 100%;
  display: block;
}

.center {
  margin: auto;
  width: 60%;
}
</style>
