<template>
  <div>
    <div v-if="loading" class="text-center">
      <div class="center" id="swingInfo"></div>
      <v-progress-circular
        :size="70"
        :width="7"
        color="black"
        indeterminate
      ></v-progress-circular>
    </div>
    <div>
      <div class="center" id="swingInfo">
        <pre class="bash"></pre>
      </div>
      <div class="center">
        <pre class="bash" id="tableContainer"></pre>
      </div>
    </div>
    <div class="center" id="rejectBarChart"></div>
  </div>
</template>

<script>
/* eslint-disable */
import * as apiCaller from "../helpers/apiCaller.js";
var Plotly = require("plotly.js-dist");
export default {
  props: ["allSwings"],
  data() {
    return {
      rejects: [],
      sessions: 0,
      totalSwings: 0,
      loading: false,
    };
  },
  created: function () {
    let profileUid = this.allSwings[0].data[0].uid;
    let url = `/user/rejects/${profileUid}`;
    Promise.allSettled([apiCaller.getUserRejects(url)]).then((response) => {
      this.rejects = response[0].value.data.rejects;
      this.sessions = response[0].value.data.sessions;
      this.totalSwings = response[0].value.data.total_swings;
    });

    this.loading = true;
  },
  methods: {
    sumUp(occurrences, keys) {
      let table = document.createElement("table");
      table.setAttribute("id", "tableContainer");
      table.setAttribute("class", "rejectTable");
      let thead = document.createElement("thead");
      thead.setAttribute("class", "rejectTable")
      let tbody = document.createElement("tbody");
      tbody.setAttribute("class", "rejectTable")
      let rowOne = document.createElement("tr");
      rowOne.setAttribute("class", "rejectTable")
      let caption = document.createElement("caption");
      caption.innerHTML = `Total Swings: ${this.totalSwings} in ${this.sessions} sessions. Total rejects: ${this.rejects.length} \n\n`;
      table.appendChild(thead);
      table.appendChild(tbody);
      thead.appendChild(caption);

      document.getElementById("tableContainer").appendChild(table);
      let headingOne = document.createElement("th");
      headingOne.innerHTML = "Rejection";
      let headingTwo = document.createElement("th");
      headingTwo.innerHTML = "Occurrences";

      rowOne.appendChild(headingOne);
      rowOne.appendChild(headingTwo);
      thead.appendChild(rowOne);

      for (let i = 0; i < keys.length; i++) {
        let row = document.createElement("tr");
        row.setAttribute("class", "rejectTable")
        let rowDataOne = document.createElement("td");
        rowDataOne.setAttribute("class", "rejectTable")
        rowDataOne.innerHTML = keys[i];
        let rowDataTwo = document.createElement("td");
        rowDataTwo.setAttribute("class", "rejectTable")
        rowDataTwo.innerHTML = occurrences[i];
        row.appendChild(rowDataOne);
        row.appendChild(rowDataTwo);
        tbody.appendChild(row);
      }
    },
  },
  watch: {
    rejects: function () {
      this.loading = false;
      const counts = {};

      // remove floats in string
      for (let i = 0; i < this.rejects.length; i++) {
        this.rejects[i] = this.rejects[i].replace(/\d*[,.]?\d+[ \t]*/g, "");
        this.rejects[i] = this.rejects[i].replace("-", "");
        this.rejects[i] = this.rejects[i].replace("inf", "");
      }

      for (const reject of this.rejects) {
        counts[reject] = counts[reject] ? counts[reject] + 1 : 1;
      }
      let keys = Object.keys(counts);
      let occurrences = [];
      for (let i = 0; i < keys.length; i++) {
        occurrences.push(counts[keys[i]]);
        keys[i] = keys[i].replace("Swing Rejected: ", "");
      }
      let barNames = [];
      for (let i = 0; i < keys.length; i++) {
        barNames.push(keys[i].substring(0, 15) + "...");
      }
      let data = [
        {
          x: barNames,
          y: occurrences,
          type: "bar",
          marker: {
            color: "rgb(142,124,195)",
          },
        },
      ];

      var layout = {
        title: "",
        height: 600,
        width: 900,
        fint: {
          size: 8,
        },
      };
      Plotly.newPlot(document.getElementById("rejectBarChart"), data, layout);
      this.sumUp(occurrences, keys);
    },
  },
};
</script>

<style>
pre.bash {
  background-color: black;
  color: white;
  font-size: medium;
  font-family: Consolas, Monaco, Lucida Console, Liberation Mono,
    DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace;
  width: 100%;
  display: block;
}

.center {
  margin: auto;
  width: 60%;
}

#tableContainer {
  margin: 0 auto;
  vertical-align: middle;
}
.rejectTable {
  border: 1px solid white;
  text-align: center;
  background-color: black;
  color: white;
}
</style>
