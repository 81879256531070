<template>
  <v-app>
    <dashboard :id="'dashExample'">
      <dash-layout
        v-for="layout in dlayouts"
        v-bind="layout"
        :debug="false"
        :key="layout.breakpoint"
      >
        <dash-item
          v-for="item in layout.items"
          v-bind.sync="item"
          :key="item.id"
        >
          <div class="content" :id="item.id">
            <!--
            <div v-if="item.id == 3" @click="resize('3dPos', item.id)">
              <v-card-title> Positions 3D </v-card-title>
              <v-combobox
                v-model="selectedSwingsToPlot"
                :items="swingNames"
                label="Select swings"
                multiple
                chips
                clearable
                dense
              ></v-combobox>
              <div id="3dPos"></div>
            </div> -->

            <div v-if="item.id == 1" @click="resize('velocity', item.id)">
              <v-card-title> Velocity </v-card-title>
              <v-combobox
                v-model="selectedSwingsVel"
                :items="swingNames"
                label="Select swings"
                multiple
                chips
                clearable
                dense
              ></v-combobox>
              <div id="velocity"></div>
            </div>

            <div v-if="item.id == 2">
              <v-card-title> Arm Rotation </v-card-title>
              <v-combobox
                v-model="selectedSwingsGyrX"
                :items="swingNames"
                label="Select swings"
                multiple
                chips
              ></v-combobox>
              <div
                @click="resize('armRotation', item.id)"
                id="armRotation"
              ></div>
            </div>

            <div v-if="item.id == 5" @click="resize('discrete', item.id)">
              <v-card-title> Discrete variable / swing </v-card-title>
              <v-combobox
                v-model="selectedSwingsDiscrete"
                :items="swingNames"
                label="Select swings"
                multiple
                chips
              ></v-combobox>
              <v-combobox
                v-model="selectedDiscrete"
                :items="discrete"
                label="Select one varibles"
                chips
                clearable
              ></v-combobox>
              <div id="discrete"></div>
            </div>

            <div v-if="item.id == 4" @click="resize('correlation', item.id)">
              <v-card-title> Correlation. Choose variables </v-card-title>
              <v-combobox
                v-model="selectedSwingsCorr"
                :items="swingNames"
                label="Select swings"
                multiple
                chips
                clearable
              ></v-combobox>
              <v-combobox
                v-model="selectedCorr"
                :items="discrete"
                label="Select two varibles"
                chips
                multiple
                clearable
              ></v-combobox>
              <div class="center" id="correlation"></div>
            </div>
          </div>

          <template v-slot:resizeBottomRight>
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 20 20"
              focusable="false"
              role="img"
              alt="icon"
              xmlns="http://www.w3.org/2000/svg"
              fill="#42b983"
              class="b-icon bi bi-arrow-down-right mx-auto"
              data-v-11c9e491
            >
              <g data-v-11c9e491>
                <path
                  fill-rule="evenodd"
                  d="M14 9.5a.5.5 0 01.5.5v5a.5.5 0 01-.5.5H9a.5.5 0 010-1h4.5V10a.5.5 0 01.5-.5z"
                  clip-rule="evenodd"
                ></path>
                <path
                  fill-rule="evenodd"
                  d="M4.646 5.646a.5.5 0 01.708 0l9 9a.5.5 0 01-.708.708l-9-9a.5.5 0 010-.708z"
                  clip-rule="evenodd"
                ></path>
              </g>
            </svg>
          </template>
        </dash-item>
      </dash-layout>
    </dashboard>
    <v-divider></v-divider>
    <div id="parentPlotPos">
      <v-col>
        <v-card-title> Position 3D</v-card-title>
        <v-combobox
          v-model="selectedSwingsToPlot"
          :items="swingNames"
          label="Select swings"
          multiple
          chips
        ></v-combobox>
      </v-col>
    </div>
    <v-divider></v-divider>
    <div id="parentPlotDiv">
      <v-col>
        <v-card-title> Custom plot fetched from backend</v-card-title>
        <v-combobox
          v-model="customSwingsToPlot"
          :items="swingNames"
          label="Select swings"
          multiple
          chips
        ></v-combobox>
        <v-container>
          <v-text-field v-model="customFunction" class="combobox" outlined>
            <template v-slot:append>
              <v-btn class="searchBtn" height="auto" text @click="addPlot">
                <v-icon>mdi-chart-timeline-variant</v-icon>
                Add
              </v-btn>
            </template>
          </v-text-field>
        </v-container>
      </v-col>
    </div>
  </v-app>
</template>

<script>
import { Dashboard, DashLayout, DashItem } from "vue-responsive-dash";
var Swing = require("../helpers/swing.js");
var dataExtractor = new Swing();
var Plotly = require("plotly.js-dist");
import * as apiCaller from "../helpers/apiCaller.js";

export default {
  props: ["allSwings"],
  components: {
    Dashboard,
    DashLayout,
    DashItem,
  },
  data() {
    return {
      swings: [],
      swingNames: [],
      selectedSwingsVel: [],
      selectedSwingsGyrX: [],
      colors: [],
      positions: [],
      discrete: [
        "LoB",
        "Transition",
        "Max hand speed",
        "Max hand speed time",
        "Time turn",
        "Time impact",
        "Max gyr x",
        "Min gyr x",
        "Backswing plane",
      ],
      selectedDiscrete: null,
      selectedSwingsDiscrete: [],
      selectedSwingsCorr: [],
      selectedCorr: [],
      selectedSwingsToPlot: [],
      customFunctionDivCount: 0,
      customSwingsToPlot: [],
      customFunction: "",
      customTraces: [],
      // layouts for dashboard
      dlayouts: [
        {
          breakpoint: "xs",
          breakpointWidth: 6000,
          numberOfCols: 3,
          items: [
            { id: "1", x: 0, y: 0, width: 1, height: 1 },
            { id: "2", x: 0, y: 0, width: 1, height: 1 },
            { id: "4", x: 0, y: 1, width: 1, height: 1 },
            { id: "5", x: 0, y: 1, width: 1, height: 1 },
          ],
        },
      ],
    };
  },
  created: function () {
    this.swings = this.allSwings[0].data;
    for (let i = 0; i < this.swings.length; i++) {
      let swing = this.swings[i];
      this.colors.push(this.getRandomColor());
      this.swingNames.push(
        swing.metadata.timestamp + ", id: " + swing.metadata.documentId
      );
    }
  },
  methods: {
    getSwings(selectedDocuments) {
      var selectedSwings = [];
      selectedDocuments.forEach((document) => {
        let docId = document.split("id: ")[1];
        for (let i = 0; i < this.swings.length; i++) {
          this.colors.push(this.getRandomColor());
          if (this.swings[i].metadata.documentId === docId) {
            selectedSwings.push(this.swings[i]);
          }
        }
      });
      return selectedSwings;
    },
    resize(plotId, dashId) {
      console.log("resize: ", dashId);
      let plot = document.getElementById(plotId);
      var update = { autosize: true };
      Plotly.relayout(plot, update);
    },
    getRandomColor() {
      const randomBetween = (min, max) =>
        min + Math.floor(Math.random() * (max - min + 1));
      const r = randomBetween(0, 255);
      const g = randomBetween(0, 255);
      const b = randomBetween(0, 255);
      const rgb = `rgb(${r},${g},${b})`;
      return rgb;
    },
    getSwingColor(swingName) {
      let docId = swingName.split(" id:")[1].replace(/\s+/g, "");
      for (let i = 0; i < this.swings.length; i++) {
        if (this.swings[i].metadata.documentId === docId) {
          return this.colors[i];
        }
      }
    },
    addPlot() {
      var id = "customPlot" + this.customFunctionDivCount;
      var newDiv = document.createElement("div");
      newDiv.setAttribute("id", id);
      var parent = document.getElementById("parentPlotDiv");
      parent.appendChild(newDiv);

      var traces = [];
      let sw_ = this.getSwings(this.customSwingsToPlot);
      for (let i = 0; i < this.customSwingsToPlot.length; i++) {
        let time = sw_[i].metadata.timestamp;
        time = Date.parse(time);
        let url = `/customFunction/${sw_[i].uid}/${time}/${
          this.customFunction
        }/${this.getSwingColor(this.customSwingsToPlot[i])}`;
        Promise.all([apiCaller.getCustom(url)]).then((result) => {
          traces.push(result[0].data);
        });
      }
      this.customTraces = traces;
    },
  },
  watch: {
    customTraces: function () {
      if (this.customTraces.length === this.customSwingsToPlot.length) {
        var layout = {
          title: this.customFunction,
          xaxis: {
            title: " Custom",
          },
          yaxis: {
            title: this.customFunction,
          },
          autosize: true,
          height: 600,
          legend: {
            yanchor: "top",
            xanchor: "center",
          },
          font: {
            size: 7,
          },
        };
        var id = "customPlot" + this.customFunctionDivCount;
        Plotly.newPlot(document.getElementById(id), this.customTraces, layout);
        this.customFunctionDivCount++;
      }
    },
    selectedSwingsGyrX: function () {
      try {
        Plotly.purge(document.getElementById("armRotation"));
      } catch (error) {
        console.log(error, "Nothing to purge");
      }
      // plots arm rotation
      let traces = [];
      let shapes = [];
      let swingsToPlot = this.getSwings(this.selectedSwingsGyrX);
      for (let i = 0; i < swingsToPlot.length; i++) {
        var intGyrX = dataExtractor.getArmRotation(swingsToPlot[i]);
        var time = dataExtractor.linspace(
          0,
          intGyrX.length / 100,
          intGyrX.length
        );
        let rgb = this.getSwingColor(this.selectedSwingsGyrX[i]);
        let data = {
          x: time,
          y: intGyrX,
          name: swingsToPlot[i].metadata.timestamp,
          mode: "lines",
          line: {
            color: rgb,
            width: 5.5,
          },
        };
        let tempo = dataExtractor.getTempo(swingsToPlot[i]);
        let turn = tempo.backswing_time;
        let impact = tempo.backswing_time + tempo.downswing_time;
        let max = Math.max(...intGyrX);
        let min = Math.min(...intGyrX);
        let verticalLineTurn = {
          type: "line",
          x0: turn,
          y0: min,
          x1: turn,
          y1: max,
          line: {
            color: rgb,
            width: 1,
            dash: "dashdot",
          },
        };
        let verticalLineImpact = {
          type: "line",
          x0: impact,
          y0: min,
          x1: impact,
          y1: max,
          line: {
            color: rgb,
            width: 1,
            dash: "dashdot",
          },
        };
        traces.push(data);
        shapes.push(verticalLineTurn);
        shapes.push(verticalLineImpact);
      }

      var layout = {
        title: "",
        xaxis: {
          title: " s (dt = 0.01)",
        },
        yaxis: {
          title: "Arm Rotation (deg)",
        },
        shapes: shapes,
        legend: {
          yanchor: "top",
          xanchor: "center",
        },
        font: {
          size: 7,
        },
      };
      Plotly.newPlot(document.getElementById("armRotation"), traces, layout);
    },
    selectedSwingsVel: function () {
      try {
        Plotly.purge(document.getElementById("velocity"));
      } catch (error) {
        console.log(error, "Nothing to purge");
      }
      let traces = [];
      let shapes = [];
      let swingsToPlot = this.getSwings(this.selectedSwingsVel);
      for (let i = 0; i < swingsToPlot.length; i++) {
        var velM = dataExtractor.getSpeed(swingsToPlot[i]);
        var time = dataExtractor.linspace(0, velM.length / 100, velM.length);
        let rgb = this.getSwingColor(this.selectedSwingsVel[i]);
        let data = {
          x: time,
          y: velM,
          name: swingsToPlot[i].metadata.timestamp,
          mode: "lines",
          line: {
            color: rgb,
            width: 5.5,
          },
        };
        let tempo = dataExtractor.getTempo(swingsToPlot[i]);
        let turn = tempo.backswing_time;
        let impact = tempo.backswing_time + tempo.downswing_time;
        let max = Math.max(...velM);
        let min = Math.min(...velM);
        let verticalLineTurn = {
          type: "line",
          x0: turn,
          y0: min,
          x1: turn,
          y1: max,
          line: {
            color: rgb,
            width: 1,
            dash: "dashdot",
          },
        };
        let verticalLineImpact = {
          type: "line",
          x0: impact,
          y0: min,
          x1: impact,
          y1: max,
          line: {
            color: rgb,
            width: 1,
            dash: "dashdot",
          },
        };
        traces.push(data);
        shapes.push(verticalLineTurn);
        shapes.push(verticalLineImpact);
      }

      var layout = {
        title: "",
        xaxis: {
          title: " s (dt = 0.01)",
        },
        yaxis: {
          title: "Hand speed (m/s)",
        },
        autosize: true,
        shapes: shapes,
        legend: {
          x: 1,
          xanchor: "left",
          y: 1,
        },
        font: {
          size: 7,
        },
      };
      this.dataVelocity = traces;
      Plotly.newPlot(document.getElementById("velocity"), traces, layout);
    },
    selectedDiscrete: function () {
      try {
        Plotly.purge(document.getElementById("discrete"));
      } catch (error) {
        console.log(error, "Nothing to purge");
      }
      // plots arm rotation
      let x = [];
      let y = [];
      let swingsToPlot = this.getSwings(this.selectedSwingsDiscrete);
      let index = 0;
      for (let i = 0; i < swingsToPlot.length; i++) {
        let yVariable = dataExtractor.getDataPoint(
          swingsToPlot[i],
          this.selectedDiscrete
        );
        if (typeof yVariable === undefined) {
          continue;
        }
        y.push(yVariable);
        x.push(index);
        index++;
      }
      let data = [
        {
          x: x,
          y: y,
          name: this.selectedDiscrete,
          type: "scatter",
          mode: "dots",
          line: {
            color: "blue",
            width: 1.5,
          },
        },
      ];

      var layout = {
        title: this.selectedDiscrete + " over time",
        xaxis: {
          title: " # swing",
        },
        yaxis: {
          title: this.selectedDiscrete,
        },
        autosize: true,
        height: 600,
        legend: {
          yanchor: "top",
          xanchor: "center",
        },
        font: {
          size: 7,
        },
      };
      Plotly.newPlot(document.getElementById("discrete"), data, layout);
    },
    selectedCorr: function () {
      try {
        Plotly.purge(document.getElementById("correlation"));
      } catch (error) {
        console.log(error, "Nothing to purge");
      }
      if (this.selectedCorr.length < 2) {
        // wait for more
        return;
      }

      let x = [];
      let y = [];
      let corrVarX = this.selectedCorr[0];
      let corrVarY = this.selectedCorr[1];
      let swingsToPlot = this.getSwings(this.selectedSwingsCorr);
      swingsToPlot.forEach((swing) => {
        let yVariable = dataExtractor.getDataPoint(swing, corrVarY);
        let xVariable = dataExtractor.getDataPoint(swing, corrVarX);
        if (
          (typeof yVarialbe === undefined || typeof xVarialbe === undefined) ===
          false
        ) {
          x.push(xVariable);
          y.push(yVariable);
        }
      });
      let data = [
        {
          x: x,
          y: y,
          name: corrVarX + "/" + corrVarY,
          mode: "markers",
          marker: {
            size: 5,
            color: "black",
          },
        },
      ];
      var layout = {
        title: "Visual Correlation: " + corrVarX + "/" + corrVarY,
        xaxis: {
          title: corrVarX,
        },
        yaxis: {
          title: corrVarY,
        },
        autosize: false,
        width: 800,
        height: 500,
      };
      Plotly.newPlot(document.getElementById("correlation"), data, layout);
    },
    selectedSwingsToPlot: function () {
      let traces = [];
      let swingsToPlot = this.getSwings(this.selectedSwingsToPlot);
      for (let i = 0; i < swingsToPlot.length; i++) {
        let pos = dataExtractor.getPos(swingsToPlot[i]);
        let data = {
          x: pos.posX,
          y: pos.posY,
          z: pos.posZ,
          name: this.selectedSwingsToPlot[i].split("id: ")[0],
          type: "scatter3d",
          mode: "lines",
          line: {
            color: this.getSwingColor(this.selectedSwingsToPlot[i]),
            width: 5.5,
          },
        };
        traces.push(data);
      }

      var layout = {
        legend: {
          x: 1,
          y: 0,
        },
        autosize: true,
      };
      var id = "3dpos";
      if (typeof document.getElementById(id) !== undefined) {
        try {
          Plotly.purge(document.getElementById(id));
        } catch (error) {
          console.log(error, "Nothing to purge");
        }
      }
      var newDiv = document.createElement("div");
      newDiv.setAttribute("id", id);
      newDiv.setAttribute("class", "childPlotPos");
      var parent = document.getElementById("parentPlotPos");
      parent.appendChild(newDiv);

      Plotly.newPlot(document.getElementById(id), traces, layout);
    },
  },
};
</script>

<style>
.wrapper [margin-left] .float-container {
  padding: 20px;
}

.childPlotPos {
  width: 100%;
  align-content: center;
}

.float-child {
  width: 50%;
  height: auto;
  float: left;
  padding: 20px;
}

.content {
  height: 100%;
  width: 100%;
  border: 2px solid #000000;
  border-radius: 5px;
}
</style>
