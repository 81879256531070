<template>
  <v-app id="app">
    <v-app-bar app color="black" dark>
      <img
        class="h-8 w-auto sm:h-10"
        src="//cdn.shopify.com/s/files/1/0316/3127/8124/t/40/assets/dewiz-logo.static.svg?v=4167416464102390068"
        alt=""
      />
    </v-app-bar>
    <v-app>
      <div v-if="!loggedIn">
        <v-main>
          <v-container class="mid" fluid fill-height>
            <v-layout align-center justify-center>
              <v-flex xs12 sm8 md4>
                <v-card class="elevation-12">
                  <v-toolbar dark color="black">
                    <img
                      class="h-8 w-auto sm:h-10"
                      src="//cdn.shopify.com/s/files/1/0316/3127/8124/t/40/assets/dewiz-logo.static.svg?v=4167416464102390068"
                      alt=""
                    />
                  </v-toolbar>

                  <v-card-text>
                    <v-card-title>Login to DeWiz Dash</v-card-title>
                    <form ref="form" @submit.prevent="login()">
                      <v-text-field
                        v-model="username"
                        name="username"
                        label="Username"
                        type="text"
                        placeholder="username"
                        required
                      ></v-text-field>

                      <v-text-field
                        v-model="password"
                        name="password"
                        label="Password"
                        type="password"
                        placeholder="password"
                        required
                      ></v-text-field>
                      <v-btn
                        type="submit"
                        class="ma-2 white--text"
                        color="black"
                        value="log in"
                        >Login</v-btn
                      >
                    </form>
                  </v-card-text>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
        </v-main>
      </div>
      <v-main>
        <v-row no-gutters>
          <v-col cols="12" sm="6" md="2">
            <v-container class="toTheLeft" grid-list-md text-xs-center>
              <v-layout v-if="loggedIn" align-left justify-left>
                <v-card align-left>
                  <v-navigation-drawer permanent>
                    <v-form>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title class="text-h6">
                            Dewiz Dash
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            Support Tool
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>

                      <v-divider></v-divider>

                      <v-list dense nav>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              ><v-text-field
                                v-model="eMail"
                                label="E-mail"
                              ></v-text-field
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              ><v-text-field
                                v-model="uuid"
                                :counter="28"
                                label="User Firestore UID"
                              ></v-text-field
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              ><v-text-field
                                v-model="supportId"
                                :counter="20"
                                label="Swing Firestore document ID"
                              ></v-text-field
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              ><v-text-field
                                v-model="sessionId"
                                :counter="36"
                                label="Firestore session ID"
                              ></v-text-field
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              ><v-text-field
                                v-model="nrSessions"
                                label="Number of sessions"
                              ></v-text-field
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              ><v-text-field
                                v-model="nrSwings"
                                label="Number of swings"
                              ></v-text-field
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              <v-combobox
                                v-model="log"
                                :items="logs"
                                label="Select Catalog"
                                chips
                                clearable
                              ></v-combobox>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              <v-menu
                                :close-on-content-click="true"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    v-model="dateFrom"
                                    label="From"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  v-model="dateFrom"
                                  @input="menu2 = false"
                                  color="black"
                                ></v-date-picker> </v-menu
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              ><v-menu
                                :close-on-content-click="true"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    v-model="dateTo"
                                    label="To"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  v-model="dateTo"
                                  @input="menu4 = false"
                                  color="black"
                                ></v-date-picker> </v-menu
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-btn
                              color="black"
                              class="ma-2 white--text"
                              @click="goToDash"
                            >
                              Fetch
                              <v-icon right dark> mdi-cloud-download </v-icon>
                            </v-btn>
                          </v-list-item-content>
                          <v-btn
                            color="black"
                            class="ma-2 white--text"
                            v-on:click="addToDash"
                          >
                            Add
                            <v-icon right dark> mdi-file-image-plus </v-icon>
                          </v-btn>
                        </v-list-item>
                      </v-list>
                    </v-form>
                  </v-navigation-drawer>
                </v-card>
              </v-layout>
            </v-container>
          </v-col>
          <v-col cols="12" sm="6" md="9">
            <div v-if="loading" id="loader">
              <v-progress-circular
                :size="70"
                :width="7"
                indeterminate
                color="black"
              ></v-progress-circular>
            </div>
            <v-container v-if="!loading">
              <v-app>
                <router-view />
                <dashboard v-if="renderComponent" :swings="swings"></dashboard>
              </v-app>
            </v-container>
          </v-col>
        </v-row>
      </v-main>
    </v-app>
  </v-app>
</template>

<script>
import Dashboard from "@/components/Dashboard";
import * as apiCaller from "@/helpers/apiCaller.js";
export default {
  name: "App",
  components: {
    Dashboard,
  },
  data: () => ({
    sessionId: null,
    payload: null,
    dateFrom: null,
    dateTo: null,
    uuid: null,
    nrSwings: null,
    nrSessions: null,
    eMail: null,
    supportId: null,
    renderComponent: false,
    swings: [],
    loading: false,
    logs: ["live", "stage", "test"],
    log: null,
    loggedIn: false,
    username: "",
    password: "",
    sessionStorage: window.sessionStorage,
  }),
  mounted: function () {
    document.title = "deWiz Dash";
  },
  beforeCreate: function () {},
  created: function () {
    this.loggedIn = false;
    if (sessionStorage.getItem("loggedOn")) {
      this.loggedIn = sessionStorage.getItem("loggedOn");
    }
  },
  methods: {
    reset() {
      this.payload["email"] = this.payload["email"] === "" ? null : this.eMail;
      this.payload["from"] = this.payload["from"] === "" ? null : this.dateFrom;
      this.payload["to"] = this.payload["to"] === "" ? null : this.dateTo;
      this.payload["uuid"] = this.payload["uuid"] === "" ? null : this.uuid;
      this.payload["numberOfSwings"] =
        this.payload["numberOfSwings"] === "" ? null : this.nrSwings;
      this.payload["numberOfSessions"] =
        this.payload["numberOfSessions"] === "" ? null : this.nrSessions;
      this.payload["supportId"] =
        this.payload["supportId"] === "" ? null : this.supportId;
      this.payload["log"] = this.payload["log"] === "" ? null : this.log;
      this.payload["sessionId"] =
        this.payload["sessionId"] === "" ? null : this.log;
    },
    login() {
      this.sessionStorage.setItem("loggedOn", this.loggedIn);
      let data = {
        username: this.username,
        password: this.password,
      };
      Promise.all([apiCaller.login(data)]).then((response) => {
        if (response[0].data === "Success") {
          this.loggedIn = true;
        } else {
          alert("Wrong password or username");
        }
      });
    },
    goToDash() {
      if (this.isAllnull()) {
        alert("Please Enter User data");
        return;
      }
      this.swings = [];
      this.loading = true;
      this.payload = {};
      this.payload["email"] = this.eMail;
      this.payload["from"] = this.dateFrom;
      this.payload["to"] = this.dateTo;
      this.payload["uuid"] = this.uuid;
      this.payload["numberOfSwings"] = this.nrSwings;
      this.payload["numberOfSessions"] = this.nrSessions;
      this.payload["supportId"] = this.supportId;
      this.payload["log"] = this.log;
      this.payload["sessionId"] = this.sessionId;

      this.reset();

      if (
        (this.payload["supportId"] !== null && this.payload["uuid"] !== null) ||
        !(this.payload["supportId"] !== "" && this.payload["uuid"] !== "")
      ) {
        apiCaller
          .apiCallerGet(
            `/getWithSupportTicket/${this.payload["supportId"]}/${this.payload["uuid"]}`
          )
          .then((response) => {
            if (typeof response === Object) {
              this.swings.push(response);
            } else {
              this.swings = response;
            }
          });
      } else {
        Promise.all([apiCaller.getSwings("/postInfo", this.payload)]).then(
          (response) => {
            if (typeof response === Object) {
              this.swings.push(response);
            } else {
              this.swings = response;
            }
          }
        );
      }

      this.forceRerender();
    },
    isAllnull() {
      return (
        this.eMail === null &&
        this.dateFrom === null &&
        this.dateTo === null &&
        this.uuid === null &&
        this.nrSwings === null &&
        this.nrSessions === null &&
        this.supportId === null
      );
    },
    forceRerender() {
      this.renderComponent = false;

      if (typeof this.swings === Object) {
        let obj = this.swings;
        this.swings = [];
        this.swings.push(obj);
      }
      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },
    addToDash() {
      if (this.isAllnull()) {
        alert("Please Enter User data");
        return;
      }

      if (this.swings.length === 0) {
        this.goToDash();
        return;
      }
      this.payload = {};
      this.payload["email"] = this.eMail;
      this.payload["from"] = this.dateFrom;
      this.payload["to"] = this.dateTo;
      this.payload["uuid"] = this.uuid;
      this.payload["numberOfSwings"] = this.nrSwings;
      this.payload["numberOfSessions"] = this.nrSessions;
      this.payload["supportId"] = this.supportId;
      this.payload["log"] = this.log;
      this.payload["sessionId"] = this.sessionId;

      this.reset();
      if (
        (this.payload["supportId"] !== null && this.payload["uuid"] !== null) ||
        !(this.payload["supportId"] !== "" && this.payload["uuid"] !== "")
      ) {
        apiCaller
          .apiCallerGet(
            `/getWithSupportTicket/${this.payload["supportId"]}/${this.payload["uuid"]}`
          )
          .then((response) => {
            for (let i = 0; i < response[0].data.length; i++) {
              this.swings[0].data.push(response.data[i]);
            }
          });
      } else {
        Promise.all([apiCaller.getSwings("/postInfo", this.payload)]).then(
          (response) => {
            for (let i = 0; i < response[0].data.length; i++) {
              this.swings[0].data.push(response[0].data[i]);
            }
          }
        );
      }
      this.forceRerender();
    },
  },
  watch: {
    swings: function () {
      if (this.swings.length === 0) {
        return;
      }

      if (this.swings.data !== undefined) {
        this.swings = [this.swings];
      }
      if (this.swings !== [] && this.swings[0].data !== undefined) {
        this.loading = false;
      }
    },
  },
};
</script>

<style>
.wrapper {
  display: grid;
  grid-template-columns: 0.2fr 1fr;
  grid-gap: 2.5%;
}

.toTheLeft {
  margin-left: 0;
}

.mid {
  margin-top: 100px;
}
.sidebar {
  height: 100%;
  min-width: 20px;
  position: absolute;
  left: 0;
  top: 0;
  padding-top: 90px;
}

.body-text {
  margin-top: 3%;
  margin-right: 10%;
}
#loader {
  position: relative;
  margin: 0 auto;
  clear: left;
  height: auto;
  z-index: 0;
  text-align: center;
  margin-top: 100px;
  margin-left: 100px;
}
.v-progress-circular {
  margin: 1rem;
}
</style>
